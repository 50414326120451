export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                title: 'Welcome!',
                authRequired: true,
              }
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                title: 'Profile',
                authRequired: true,
              },
            },
            {
                path: '/orders',
                name: 'order',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                  title: "Orders List",
                  authRequired: true,
                },
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-show',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                title: "View Order",
                authRequired: true,
              },
            },
            {
              path: '/orders/:orderId(\\d+)/deliveries/:deliveryId(\\d+)',
              name: 'delivery-show',
              component: () => import('@views/orders/DeliveryShow.vue'),
              meta: {
                title: "View Delivery",
                authRequired: true,
              },
            },
            {
              path: '/categories',
              name: 'categories',
              component: () => import('@views/categories/CategoryList.vue'),
              meta: {
                title: "Categories List",
                authRequired: true,
              },
            },
            {
              path: '/categories/create',
              name: 'categories-create',
              component: () => import('@views/categories/CreateCategory.vue'),
              meta: {
                title: "Create Category",
                authRequired: true,
              },
            },
            {
              path: '/categories/:categoryId(\\d+)',
              name: 'categories-view',
              component: () => import('@views/categories/CategoryShow.vue'),
              meta: {
                title: "View Category",
                authRequired: true,
              },
            },
            {
              path: '/products',
              name: 'products',
              component: () => import('@views/products/ProductList.vue'),
              meta: {
                title: "Products List",
                authRequired: true,
              },
            },
            {
              path: '/products/create',
              name: 'products-create',
              component: () => import('@views/products/CreateProduct.vue'),
              meta: {
                title: "Create Product",
                authRequired: true,
              },
            },
            {
              path: '/products/:productId(\\d+)',
              name: 'products-view',
              component: () => import('@views/products/ProductShow.vue'),
              meta: {
                title: "View Product",
                authRequired: true,
              },
            },
            {
              path: '/requirements',
              name: 'requirements',
              component: () => import('@views/requirements/RequirementList.vue'),
              meta: {
                title: "Requirements",
                authRequired: true,
              },
            },
            {
              path: '/requirement-sections',
              name: 'requirement-sections',
              component: () => import('@views/requirements/Sections.vue'),
              meta: {
                title: "Requirements sections",
                authRequired: true,
              },
            },
            {
              path: '/requirement-sections/:sectionId(\\d+)',
              name: 'requirement-sections-view',
              component: () => import('@views/requirements/SectionShow.vue'),
              meta: {
                title: "View Requirements in a section",
                authRequired: true,
              },
            },
            {
              path: '/attributes',
              name: 'attributes',
              component: () => import('@views/settings/Attributes.vue'),
              meta: {
                title: "Attributes",
                authRequired: true,
              },
            },
            {
              path: '/addons',
              name: 'addons',
              component: () => import('@views/settings/Addons.vue'),
              meta: {
                title: "Addons",
                authRequired: true,
              },
            },
            {
              path: '/frequent-questions',
              name: 'frequent-questions',
              component: () => import('@views/settings/FrequentQuestions.vue'),
              meta: {
                title: "FrequentQuestions",
                authRequired: true,
              },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
                path: '/users/create',
                name: 'user-create',
                component: () => import('@views/users/UserCreate.vue'),
                meta: {
                  title: "Create User",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              redirect: to => {
                return { path: `/users/${to.params.userId}/edit` }
              },
              meta: {
                title: "Vew User",
                authRequired: true,
              },
            },
            {
                path: '/users/:userId(\\d+)/edit',
                name: 'user-edit',
                component: () => import('@views/users/edit/UserEdit.vue'),
                meta: {
                  title: "Vew User",
                  authRequired: true,
                },
            },
            {
              path: '/chats/:userId(\\d+)',
              name: 'user-chat',
              component: () => import('@views/chats/Chats.vue'),
              meta: {
                title: "Chat user",
                authRequired: true,

              },
            },
            {
                path: '/reviews',
                name: 'reviews',
                component: () => import('@views/reviews/ReviewList.vue'),
                meta: {
                  title: 'Reviews',
                  authRequired: true,
                }
            },
            {
                path: '/payments',
                name: 'payments',
                component: () => import('@views/payments/Payments.vue'),
                meta: {
                  title: "Payments",
                  authRequired: true,
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notifications/NotificationList.vue'),
                meta: {
                  title: "Notifications",
                  authRequired: true,
                }
            },
            {
                path: '/transactions',
                name: 'transactions',
                component: () => import('@views/transaction/TransactionList.vue'),
                meta: {
                  title: "Transactions",
                  authRequired: true,
                }
            },
            {
                path: '/coupons',
                name: 'coupons',
                component: () => import('@views/settings/Coupons.vue'),
                meta: {
                  title: "Coupons",
                  authRequired: true,
                }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue'),
                meta: {
                  title: "Login",
                  guestRequired: true,
                }
            },
            {
                path: '/error-404',
                name: 'error-404',
                component: () => import('@/views/pages/Error404.vue'),
                meta: {
                  title: "404 - Error"
                }
            },
            {
                path: '/error-500',
                name: 'error-500',
                component: () => import('@/views/pages/Error500.vue'),
                meta: {
                  title: "500 - Error"
                }
            },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/error-404'
    }
]