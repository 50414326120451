
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleNotification from "./modules/notification.js"
import moduleCategory from "./modules/category.js"
import moduleProduct from "./modules/product.js"
import moduleOrder from './modules/order.js'
import moduleProjects from './modules/projects.js'
import moduleUser from './modules/user.js'
import moduleReview from './modules/review.js'
import moduleRequirement from './modules/requirement.js'
import modulePlan from './modules/plan.js'
import moduleAttribute from './modules/attribute.js'
import moduleFrequentQuestion from './modules/frequent-questions.js'
import moduleChat from './modules/chat.js'
import moduleAddon from './modules/addons.js'
import moduleSections from './modules/requirement-sections.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        notification: moduleNotification,
        orderList: moduleOrder,
        categoryList: moduleCategory,
        productList: moduleProduct,
        userList: moduleUser,
        reviewList: moduleReview,
        requirement: moduleRequirement,
        planList: modulePlan,
        attribute: moduleAttribute,
        frequentQuestion: moduleFrequentQuestion,
        chat: moduleChat,
        addon: moduleAddon,
        section: moduleSections,
        project: moduleProjects,
    },
    strict: process.env.NODE_ENV !== 'production'
})
