import axios from "@/plugins/axios.js"

const actions = {
  fetchProjects({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/projects?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_PROJECTS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchProject({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/projects/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_PROJECT', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchMessages({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/project-messages?project_id=${itemId}`)
        .then((response) => {
          if(response.data.success){
              commit('SET_PROJECT_MESSAGES', response.data.data)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  multipleAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(payload.action, payload.formData)
        .then((response) => {
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {}


const mutations = {
  SET_PROJECTS(state, data) {
    state.pageData = data
  },
  SET_PROJECT(state, project){
  	state.project = project
  },
  DELETE_PROJECT(state, itemId){
    const itemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR MESSAGES********************/
  SET_PROJECT_MESSAGES(state, data){
    state.project.messages = [...data]
  },
  ADD_MESSAGE(state, item){
    state.project.messages.push(item)
  },
  UPDATE_MESSAGE(state, item){
    const dataIndex = state.project.messages.findIndex((a) => a.id == item.id);
    Object.assign(state.project.messages[dataIndex], item);
  },
  DELETE_MESSAGE(state, itemId){
    const itemIndex = state.project.messages.findIndex((item) => item.id == itemId)
      state.project.messages.splice(itemIndex, 1)
  },
  SET_MESSAGE(state, item){
    state.item = {...item}
  },

}

const state = {
  pageData: {
    data: []
  },
  project: null,
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

