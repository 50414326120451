import { getUserStorable } from '@/auth/utils'

export default {
  TOGGLE_LOADER(state, loader) {
    state.layout.loader = loader;
  },
  CHANGE_LAYOUT(state, layoutType) {
    state.layout.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layout.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.layout.leftSidebarType = leftSidebarType;
  },
  CHANGE_LEFT_SIDEBAR_SIZE(state, leftSidebarSize) {
    state.layout.leftSidebarSize = leftSidebarSize;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.layout.topbar = topbar;
  },
  CHANGE_POSITION(state, menuPosition) {
    state.layout.menuPosition = menuPosition;
  },
  CONTENT_PAGE_POSITION(state, position) {
    state.layout.contentPageFixed = position;
  },
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey);
    state.AppActiveUser = null;
  },

  SET_AUTH_TOKEN(state, data){
    const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
    const config = {
        token: data.accessToken,
        tokenExpirationDate: expirationTime.toISOString(),
        user: getUserStorable(data.user),
    }
    const encryptedValue = btoa(escape(JSON.stringify(config)));
    localStorage.setItem(state.localUserKey, encryptedValue);
  },
  UPDATE_USER_INFO(state, user) {
    if(!user.id){return};
    state.AppActiveUser 
        ? Object.assign(state.AppActiveUser, user)
        : state.AppActiveUser = {...user};

    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = getUserStorable(user);
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },

  /************** FOR COUPONS *****************/
  ADD_COUPON(state, coupon){
    state.coupons.unshift(coupon);
  },
  SET_COUPONS(state, coupons){
    state.coupons = [...coupons];
  },
  UPDATE_COUPON(state, coupon){
    const dataIndex = state.coupons.findIndex((f) => f.id == coupon.id)
    Object.assign(state.coupons[dataIndex], coupon)
  },
  DELETE_COUPON(state, itemId){
    const ItemIndex = state.coupons.findIndex((item) => item.id == itemId)
    state.coupons.splice(ItemIndex, 1);
  },

  /************** FOR SUBSCRIPTIONS *****************/
  SET_SUBSCRIPTIONS(state, subscriptions){
    state.subscriptions = [...subscriptions]
  },
  UPDATE_SUBSCRIPTION(state, subscription){
    if(!state.subscriptions.length) return
    const dataIndex = state.subscriptions.findIndex((sub) => sub.id == subscription.id);
    Object.assign(state.subscriptions[dataIndex], subscription);
  },
}


