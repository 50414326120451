import axios from "@/plugins/axios.js"

const  actions = {
  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/users?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit, state }, force = false) {
    if(!force && state.roles.length) return
    return new Promise((resolve, reject) => {
      axios.get("/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}


const getters  = {

}


const mutations = {
  ADD_USER(state, user) {
    state.pageData.data.push(user)
  },
  SET_USERS(state, data) {
    state.pageData = data
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_USER(state, user) {
    state.user = user
    if(state.pageData.data?.length){
        const dataIndex = state.pageData.data.findIndex((u) => u.id == user.id);
        Object.assign(state.pageData.data[dataIndex], user);
    }
  },
  DELETE_USER(state, userId) {
      state.user = {}
      const userIndex = state.pageData.data.findIndex((u) => u.id == userId)
      state.pageData.data.splice(userIndex, 1)
  },
}

const state = {
  pageData: {
    data: []
  },
  roles: [],
  user: {},
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

